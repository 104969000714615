import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, CircularProgress } from '@mui/material';
import { format } from 'date-fns';

import useSubscription from '@/hooks/account/useSubscription';
import useAccountContext from '@/hooks/context/useAccountContext';
import PaymentAPI from '@/network/PaymentAPI';
import { handleApiError } from '@/utility/api';

import Subscription from '../subscription/Subscription';
import SubscriptionBanner from '../subscription/SubscriptionBanner';
import SubscriptionPerksCard from '../subscription/SubscriptionPerksCard';
import DialogModal from '../utility/modals/DialogModal';

const SubscriptionSettings = () => {
  const { t } = useTranslation();

  const { accountId } = useAccountContext();
  const { isSubscribed, isMonthly, isFreeTrial, expiresOn, isWeb, status } = useSubscription();

  const [isManageSubscription, setIsManageSubscription] = useState(false);
  const [isCancelSubscription, setIsCancelSubscription] = useState(false);
  const [creatingLink, setCreatingLink] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const goToManageSubscription = () => {
    setIsManageSubscription(true);
  };

  const linkOutToStripeManageSubscription = useCallback(async () => {
    setCreatingLink(true);
    try {
      if (!accountId) return;
      const response = await PaymentAPI.postSubscriptionManageUrl({ accountId, returnUrl: window.location.href });
      window.location.href = response.data.url;
    } catch (error: unknown) {
      handleApiError({ error });
    } finally {
      setCreatingLink(false);
    }
  }, [accountId]);

  return (
    <>
      <DialogModal
        open={dialogOpen}
        output={() => setDialogOpen(false)}
        isInfoDialog={true}
        title="SUBSCRIPTION.SETTINGS.YOU-ARE-SUBSCRIBED-THROUGH-OUR-APP"
        content="SUBSCRIPTION.SETTINGS.HEAD-TO-APP"
      />
      {!isManageSubscription && (
        <div className="text-center w50p w100p-lg-down">
          {!isSubscribed && (
            <div className="card d-flex gap20 text-left">
              <img src="/images/icons/graph.svg" alt="" />
              <div>
                <p className="text-faded">{t('SUBSCRIPTION.SETTINGS.FREE-SUBSCRIPTION')}</p>
                <p>{t('SUBSCRIPTION.SETTINGS.BASIC-PACKAGE')}</p>
              </div>
            </div>
          )}

          {!isSubscribed && (
            <div className="mt20 text-left">
              <Subscription type="banner-small" />
            </div>
          )}

          {isSubscribed && (
            <div className="text-left">
              <div className="mb20">
                <SubscriptionBanner />
              </div>
              <h3 className="text-center mb20">{t('SUBSCRIPTION.INCLUDED-IN-YOUR-PLAN')}</h3>
              <SubscriptionPerksCard isFull={true} />
            </div>
          )}
          {isSubscribed && (
            <Button
              className="m0 mt20 btn-white"
              onClick={() => {
                if (isWeb) {
                  goToManageSubscription();
                } else {
                  setDialogOpen(true);
                }
              }}
            >
              {t('SUBSCRIPTION.SETTINGS.MANAGE-SUBSCRIPTION')}
            </Button>
          )}
        </div>
      )}
      {isManageSubscription && isSubscribed && (
        <div className="w50p w100p-lg-down">
          <h3>
            {t('SUBSCRIPTION.SETTINGS.YOU-ARE-ON')}{' '}
            {isMonthly ? t('SUBSCRIPTION.PLANS.MONTHLY') : t('SUBSCRIPTION.SETTINGS.ANNUAL')}{' '}
            {t('SUBSCRIPTION.SETTINGS.PLAN')}
          </h3>
          {!isCancelSubscription && (
            <div>
              <p className="text-faded mt20">
                {isFreeTrial
                  ? t('SUBSCRIPTION.SETTINGS.YOUR-TRIAL-ENDS-ON')
                  : t('SUBSCRIPTION.SETTINGS.YOUR-SUBSCRIPTION-ENDS-ON')}{' '}
                {format(new Date(expiresOn), 'dd/MM/yy')}.{' '}
                {status !== 'ending' && status !== 'ended' && t('SUBSCRIPTION.SETTINGS.IF-YOU-DONT-CANCEL')}{' '}
                {t('SUBSCRIPTION.PLANS.FOR-THE-YEAR')}
              </p>

              <div className="w100p mt20">
                {status !== 'ending' && status !== 'ended' && (
                  <Button
                    className="ml0"
                    onClick={() => {
                      setIsCancelSubscription(true);
                    }}
                  >
                    <span className="text-error">{t('SUBSCRIPTION.SETTINGS.CANCEL-SUBSCRIPTION')}</span>
                  </Button>
                )}
                {(status === 'ending' || status === 'ended') && (
                  <Button
                    className="ml0"
                    onClick={() => {
                      linkOutToStripeManageSubscription();
                    }}
                  >
                    <span className="text-error">{t('SUBSCRIPTION.SETTINGS.RENEW-SUBSCRIPTION')}</span>
                  </Button>
                )}
              </div>
            </div>
          )}
          {isCancelSubscription && (
            <div>
              <p className="text-faded mt20 mb20">{t('SUBSCRIPTION.SETTINGS.ARE-YOU-SURE-YOU-WANT-TO-CANCEL')}</p>
              <SubscriptionPerksCard isCancel={true} />
              <div className="w100p mt20 text-center">
                <Button
                  onClick={() => {
                    linkOutToStripeManageSubscription();
                  }}
                >
                  <span className="text-error">
                    {creatingLink ? <CircularProgress size={16} /> : t('SUBSCRIPTION.SETTINGS.YES-CANCEL')}
                  </span>
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SubscriptionSettings;
